import React, { useState, useEffect, forwardRef, useImperativeHandle } from 'react';
import { Row, Col, Switch, Tooltip, Radio, Upload, message, Button } from 'antd/lib';
import isEmpty from 'lodash/isEmpty';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import _ from 'lodash';
import {
  ArrowLeftOutlined,
  CloseOutlined,
  CheckOutlined,
  CloseCircleOutlined,
  LoadingOutlined,
  DownOutlined,
  UpOutlined,
  UpCircleFilled,
  DownCircleFilled
} from '@ant-design/icons';
import styled from 'styled-components';

import { request } from '../../../utils/axios';
import { iff } from '../../../utils/iff';
import { AmplitudeInstance } from '../../../utils/amplitude';
import { getHeaders } from '../../../utils/common-methods';
import { Loader } from '../../Loader/Loader';
import {
  StyledButton,
  Label,
  StyledSpan,
  StyledP,
  StyledDrawer,
  StyledInput,
  StyledError,
  StyledDiv,
  StyledSpin,
  StyledImageListDropwodn,
  StyledBoldP,
  StyledImg,
  StyledRadioButtonPopup,
  StyledSwitchSection
} from '../../../utils/styles.theming';
import get from 'lodash/get';
import ImgIcon from '../../../public/thumbnail.svg';
import TipIcon from '../../../public/Tip.svg';
import LinkIcon from '../../../public/link.svg';
import { ErrorModal } from '../../common/Modal/ErrorModal';
import '../../../components/settings/Component.css';
import './filterModel.css';

const StyledAttributeSection = styled.div`
  ::-webkit-scrollbar-thumb {
    background: ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')};
    -webkit-box-shadow: inset 0 3px 2px 6px
      ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')} !important;
    cursor: pointer;
  }
  .ant-radio-wrapper {
    width: 100%;
    display: initial;
  }
  span.ant-radio {
    margin: 0px 5px;
    display: inline-block;
  }
  .ant-radio-checked .ant-radio-inner {
    border-color: ${(props) => get(props, 'theme.colors.primary')};
  }
  .ant-radio-inner::after {
    background-color: ${(props) => get(props, 'theme.colors.primary')};
  }
  .ant-radio-wrapper:hover .ant-radio,
  .ant-radio:hover .ant-radio-inner,
  .ant-radio-input:focus + .ant-radio-inner {
    border-color: ${(props) => get(props, 'theme.colors.primary')};
  }
`;

let shapefileData = [];
let checkActivationAPICallsCount = 0;
export const SideBarFilterModel = forwardRef((props, ref) => {
  const antIcon = <LoadingOutlined className='spin-dot' style={{ fontSize: 16 }} spin />;
  const { t } = useTranslation();
  const [selectedImages, setSelectedImages] = useState([]);
  const [filterResponse, setFilterResponse] = useState({});
  const [selectedMonthYear, setSelectedMonthYear] = useState('');
  const [selectedSource, setSelectedSource] = useState([]);
  const [zoneNumber, setZoneNumber] = useState('');
  const [stepper, setStepper] = useState(1);
  const [removeOutlierPixel, setRemoveOutlierPixel] = useState('');
  const [zonesErrorMsg, setZonesErrorMsg] = useState(null);
  const [removeOutlierPixelErrorMsg, setRemoveOutlierPixelErrorMsg] = useState(null);
  const [selectedShapeFile, setSelectedShapeFile] = React.useState({});
  const [shapeFileError, setShapeFileError] = React.useState(null);
  const [isActiveImageListDropdown, setIsActiveImageListDropdown] = useState({});
  const [errorMessage, setErrorMessage] = useState(null);
  const [showUploadSection, setShowUploadSection] = React.useState(true);
  const [isImgListToggleOn, setIsImgListToggleOn] = React.useState(
    props.filterPopupData.isImgListToggleOn
  );
  const [loader, setLoader] = useState(false);
  const ImageSelectCheckbox = require('../../../public/ImageSelect_check_Icon.svg');

  useEffect(() => {
    if (props.filterPopupData?.selectedDate && props.filterPopupData?.selectedEndDate) {
      let startDateYear =
        props.filterPopupData.selectedDate?.year();
      let startDateMonth = new Date(props.filterPopupData.selectedDate).toLocaleString('default', {
        month: 'short'
      });
      let endDateYear =
        props.filterPopupData.selectedEndDate?.year();
      let endDateMonth = new Date(props.filterPopupData.selectedEndDate).toLocaleString('default', {
        month: 'short'
      });
      if (endDateMonth !== 'Invalid Date') {
        setSelectedMonthYear(`${t(startDateMonth)} ${startDateYear} - ${t(endDateMonth)} ${endDateYear}`);
      } else {
        setSelectedMonthYear(`-- --`);
      }
      setSelectedSource(props.filterPopupData.selectedSource);
      setIsImgListToggleOn(props.filterPopupData.isImgListToggleOn);
      modifyFilterResponse(props.filterResponseData, startDateYear);
      defaultInitialization();
      setSelectedElevation({
        selectedAttribute: 'tiff',
        geoJson: null,
        shapeFileName: null,
        isElevationFile: true
      });
    }
  }, [props.filterResponseData, props.filterPopupData]);

  const [shapeFiles, setShapeFiles] = React.useState([]);
  const [uploading, setUploading] = React.useState(false);
  const uploadProps = {
    multiple: true,
    accept: '.zip, .tif, .tiff',
    showUploadList: true,
    onRemove: (file) => {
      setShapeFiles((prevFileList) => {
        const index = prevFileList.indexOf(file);
        const newFileList = prevFileList.slice();
        newFileList.splice(index, 1);
        return newFileList;
      });
    },
    beforeUpload: (file) => {
      let fileExt = file.name.split('.');
      fileExt = fileExt[fileExt.length - 1];
      fileExt = fileExt.toLowerCase();
      let fileType = file.type.toLowerCase();
      if (
        (fileType.indexOf('zip') > -1 && fileExt === 'zip') ||
        (fileType.indexOf('tif') > -1 && fileExt === 'tif') ||
        (fileType.indexOf('tiff') > -1 && fileExt === 'tiff')
      ) {
        setShapeFiles((prevFileList) => [
          ...prevFileList,
          {
            file: file,
            name: file.name
          }
        ]);
        return false;
      } else {
        message.error(`The file ("${file.name}") is not accepted`);
      }
    },
    shapeFiles
  };

  const getSelectedShapeFile = (eve) => {
    const uid = eve.target.value;
    const file = shapeFiles.find((fileData) => fileData.file.uid === uid);
    setSelectedShapeFile(file);
    setShapeFileError(null);
  };

  const handleUpload = () => {
    const formData = new FormData();
    formData.append('shapefile', selectedShapeFile.file);
    let payload = formData;
    if (selectedShapeFile?.file) {
      setUploading(true);
      request
        .post('/upload', payload, {
          baseURL: process.env.MZ_API_URL,
          headers: getHeaders(),
        })
        .then((res) => {
          if (res.status === 200) {
            setUploading(false);
            let updateShapeFiles = shapeFiles.map((fileData) => {
              if (fileData.name === selectedShapeFile.name) {
                fileData.shapeFileResponse = res.data;
              }
              return fileData;
            });
            setShapeFiles(updateShapeFiles);
            if (selectedShapeFile.file.type === 'image/tiff') {
              let updateShapeFilesWithTiffFile = updateShapeFiles.map((shapeFile) => {
                if (
                  shapeFile &&
                  shapeFile.name === selectedShapeFile.name &&
                  shapeFile.file.type === 'image/tiff'
                ) {
                  let attributeData = {
                    selectedAttribute: 'tiff',
                    geoJson: shapeFile.shapeFileResponse.geojson,
                    shapeFileName: shapeFile.name,
                    selectedTiffAttribute: 'DN'
                  };
                  props.getSelectedShapeFileGeoJson(attributeData);
                  shapeFile.selectedAttribute = 'tiff';
                }
                return shapeFile;
              });
              setShapeFiles(updateShapeFilesWithTiffFile);
              setZonesErrorMsg(null);
            }
          }
        })
        .catch((err) => {
          setUploading(false);
          message.error('upload failed.');
        });
    } else {
      setShapeFileError('Please select a shape file');
    }
  };

  React.useEffect(() => {
    setShowUploadSection(true);
    if (stepper === 1) {
      setRemoveOutlierPixelErrorMsg(null);
    }
  }, [stepper]);

  const getSelectedShapeFileAttribute = (selectedFileData, attribute) => {
    setStepper(1);
    setRemoveOutlierPixel('');
    setZonesErrorMsg(null);
    setRemoveOutlierPixelErrorMsg(null);

    let attributeData = {
      selectedAttribute: attribute,
      geoJson: selectedFileData.shapeFileResponse.shapefile_geojson,
      shapeFileName: selectedFileData.name
    };

    let updateShapeFiles = shapeFiles.map((shapeFile) => {
      if (shapeFile.name === selectedFileData.name) {
        if (shapeFile.selectedAttribute === attribute) {
          shapeFile.selectedAttribute = null;
          props.onImageDelete(attributeData, true);
          props.getSelectedShapeFileGeoJson(null);
        } else {
          shapeFile.selectedAttribute = attribute;
          props.getSelectedShapeFileGeoJson(attributeData);
        }
      }
      return shapeFile;
    });
    setShapeFiles(updateShapeFiles);
  };
  const modifyFilterResponse = (filterResponseData, yearValue) => {
    checkActivationAPICallsCount = 0;
    if (
      Object.keys(filterResponseData).length > 0 &&
      filterResponseData?.images
    ) {
      filterResponseData.images.map((data) => {
        data['__isActiveImage'] = false;
        data['__acquisition_date_formatedLabel'] = `${new Date(data.acquisition_date).toLocaleString('default', {
          month: 'short'
        })} ${new Date(data.acquisition_date).getDate()}, ${new Date(
          data.acquisition_date
        ).getFullYear()}`;
        data.__requestsCount = 0;
        data.__month = new Date(data.acquisition_date).toLocaleString('default', { month: 'long' });
        data.imagePreview = `${process.env.MZ_RSS_API_URL}/images/${data.image_id}/ndvi/falsecolor/png`
        return data;
      });
      let groupByYearFilterResponse = _.groupBy(filterResponseData.images, (data) => {
        return new Date(data.acquisition_date).getFullYear();
      });
      let groupByMonthFilterResponse;
      let groupByDayFilterResponse;
      let yearMonthFilterResponseData = [];
      Object.keys(groupByYearFilterResponse).forEach((yearData) => {
        groupByMonthFilterResponse = _.groupBy(groupByYearFilterResponse[yearData], (data) => {
          return new Date(data.acquisition_date).toLocaleString('default', {
            month: 'long'
          });
        });
        Object.keys(groupByMonthFilterResponse).forEach((monthData) => {
          groupByDayFilterResponse = _.groupBy(groupByMonthFilterResponse[monthData], (data) => {
            return new Date(data.acquisition_date).toLocaleDateString();
          });
        });
        return yearMonthFilterResponseData.push({
          year: yearData,
          imageList: groupByMonthFilterResponse
        });
      });
      let reverseYearMonthFilterResponseData = yearMonthFilterResponseData.toReversed()
      let _modifiedfilterResponseData = {
        assets: reverseYearMonthFilterResponseData
      };
      setFilterResponse(_modifiedfilterResponseData);
    } else {
      setFilterResponse(filterResponseData);
    }
  };


  /**
   * Reinitialization with default value when navigating 2nd time without refresh
   */
  const defaultInitialization = () => {
    setSelectedImages(props.selectedImages);
    setZoneNumber('');
    setStepper(1);
    setRemoveOutlierPixel('');
    setZonesErrorMsg(null);
    setRemoveOutlierPixelErrorMsg(null);
  };

  const onModifyFilter = () => {
    AmplitudeInstance.onModifyFilter();
    props.showFilterPopup();
  };
  const callRSSAPIForImage = (image, year) => {
    if (!image.fetch_image_again) {
      let authHeader = getHeaders();
      setLoader(true);
      let thumbnailURL = image.imagePreview;
      request
        .get(thumbnailURL, {
          responseType: 'blob',
          headers: {
            'Content-Type': 'image/png,image/jpeg,image/webp,application/json',
            Authorization: authHeader.common.Authorization
          },
        })
        .then((response) => {
          if (response.status !== 200) {
            setErrorMessage(response.message || 'Something went wrong');
            setLoader(false);
            return;
          }
          return response.data;
        })
        .then((blob) => {
          setLoader(true);
          const reader = new FileReader();
          reader.onload = () => {
            const imageDataUrl = reader.result;
            image.imagePreview = imageDataUrl
            image.fetch_image_again = true
            onSelectImage(image, year);
          };
          reader.readAsDataURL(blob);
          setLoader(false);
        })
        .catch((error) => {
          setLoader(false);
          if (error?.response?.data?.message) {
            setErrorMessage(error.response.data.message);
          } else {
            setErrorMessage('Something went wrong');
          }
          onSelectImage(image, year)
        })
    } else {
      onSelectImage(image, year);
    }
  }

  /**
   * for getting selected image data from respective year in IMAGELIST sectioin sidebar .
   * @param {stirng} image
   * @param {string} geom_id
   */
  const onSelectImage = (image, year) => {
    setStepper(1);
    setRemoveOutlierPixel('');
    setZonesErrorMsg(null);
    setRemoveOutlierPixelErrorMsg(null);
    isActiveImage(image, year);
    if (image) {
      let imageIndex = selectedImages.findIndex((data) => {
        return data.image.acquisition_date === image.acquisition_date;
      });
      if (imageIndex !== -1) {
        if (activeRenderedNdviRgbVariabilitImage === image.image_id) {
          AmplitudeInstance.onImageDeselection(image.image_id);
          setActiveRenderedNdviRgbVariabilitImage(null);
          props.showSelectedNdviRgbVariabilitImages(null);
        }
        let clonedSelectedImages = [...selectedImages];
        clonedSelectedImages.splice(imageIndex, 1);
        setSelectedImages(clonedSelectedImages);
        props.getSelectedImages(clonedSelectedImages);
      } else {
        setActiveRenderedNdviRgbVariabilitImage(image.image_id);
        let tempSelectedImg = selectedImages;
        let currentSelectedImg = {
          year: year,
          image: image,
          month: image.__month
        };
        tempSelectedImg.push(currentSelectedImg);
        let uniquelySelectedImagesYearWise = _.reject(tempSelectedImg, function (uniqueImage) {
          return uniqueImage.year === year && uniqueImage.month === image.__month;
        });
        uniquelySelectedImagesYearWise.push(currentSelectedImg);
        setSelectedImages(uniquelySelectedImagesYearWise);
        props.getSelectedImages(uniquelySelectedImagesYearWise);
        AmplitudeInstance.onImageSelection(image.image_id);
        props.showSelectedNdviRgbVariabilitImages(image);
      }
    }
  };

  /**
   * For checked and uncheck selected image from respective year in IMAGELIST sectioin sidebar
   */

  const isActiveImage = (selectedImage, year) => {
    const modifiedResp =
      filterResponse.assets?.map((yearData) => {
        if (year === yearData.year) {
          yearData.imageList[selectedImage.__month].map((image) => {
            if (selectedImage.acquisition_date == image.acquisition_date) {
              image.__isActiveImage = !image.__isActiveImage;
            } else {
              image.__isActiveImage = false;
            }
          });
        }
        return yearData;
      });
    setFilterResponse({ ...filterResponse, assets: modifiedResp });
  };

  const getZoneNumber = (event) => {
    setZoneNumber(event.target.value);
    isValidImageAndZoneData(event.target.value);
  };

  const isValidImageAndZoneData = (zoneNo) => {
    shapefileData = [];
    shapeFiles.map((fileData) => {
      if (fileData.file.type === 'image/tiff' && fileData.shapeFileResponse) {
        shapefileData.push({
          shapefile_name: fileData.shapeFileResponse.filename,
          file_type: 'tiff'
        });
      } else if (fileData.selectedAttribute && fileData.shapeFileResponse) {
        shapefileData.push({
          shapefile_name: fileData.shapeFileResponse.filename,
          shapefile_attribute: fileData.selectedAttribute
        });
      }
    });
    const latestZoneNumber = zoneNo ? Number(zoneNo) : Number(zoneNumber);
    if (selectedImages.length === 0 && shapefileData.length === 0) {
      setZonesErrorMsg('Please select at least one image or shape file attribute');
      return false;
    }
    if (
      latestZoneNumber === 0 ||
      latestZoneNumber < 2 ||
      latestZoneNumber > 15 ||
      isNaN(latestZoneNumber)
    ) {
      setZonesErrorMsg('Zone number should be between 2 to 15');
      return false;
    }
    if (latestZoneNumber % 1 !== 0) {
      setZonesErrorMsg('Zone number should not be a decimal');
      return false;
    }
    setZonesErrorMsg(null);
    return true;
  };

  const isValidRemoveOutlierPixel = (removeOutlier, eventType) => {
    const latestRemoveOutlierPixel = removeOutlier
      ? Number(removeOutlier)
      : Number(removeOutlierPixel);
    if (latestRemoveOutlierPixel === 0 && eventType !== undefined) {
      setRemoveOutlierPixelErrorMsg('Outlier pixel is required');
      return false;
    }
    if (
      latestRemoveOutlierPixel % 1 !== 0 ||
      latestRemoveOutlierPixel < 0 ||
      (latestRemoveOutlierPixel === 0 && eventType !== undefined) ||
      isNaN(latestRemoveOutlierPixel)
    ) {
      setRemoveOutlierPixelErrorMsg('Outlier pixel should be a non zero positive number');
      return false;
    }
    setRemoveOutlierPixelErrorMsg(null);
    return true;
  };

  const onCreateZones = () => {
    if (isValidImageAndZoneData()) {
      let payload = preparePayload(true);
      payload['zone'] = Number(zoneNumber);
      payload['threshold_in_px'] = 2;
      AmplitudeInstance.onCreateCluster(payload);
      props.createZones(payload);
    }
  };

  /**
   * Preparing payload for createZones & RemoveOutlier
   */
  const preparePayload = (withImageIds) => {
    let image_ids = [];
    selectedImages.forEach((img) => {
      if (img.image.image_id) {
        image_ids.push(img.image.image_id);
      }
    });
    let payload = {
      image_ids: image_ids,
      shapefile: shapefileData,
    };
    return payload;
  };

  const getRemoveOutlierPixel = (event) => {
    setRemoveOutlierPixel(event.target.value);
    isValidRemoveOutlierPixel(event.target.value);
  };

  const onRemoveOutlierPixel = () => {
    if (isValidRemoveOutlierPixel(removeOutlierPixel, 'RemoveOutlier')) {
      setRemoveOutlierPixelErrorMsg(null);
      let payload = preparePayload(true);
      payload['zone'] = Number(zoneNumber);
      payload['threshold_in_px'] = Number(removeOutlierPixel);
      AmplitudeInstance.onRemoveOutlier(payload);
      props.createZones(payload, 'onRemoveOutlier');
    }
  };
  const downloadZonesData = () => {
    if (isValidRemoveOutlierPixel()) {
      let payload = preparePayload();
      payload['zone'] = Number(zoneNumber);
      const _removeOutlierPixel = Number(removeOutlierPixel);
      payload['threshold_in_px'] = _removeOutlierPixel >= 1 ? _removeOutlierPixel : 2;
      payload['download'] = 'true';
      payload['export'] = 'true'
      AmplitudeInstance.onDownload();
      props.downloadZonesData(payload);
    }
  };

  const backToGenerateZone = () => {
    setStepper(1);
  };
  const [
    activeRenderedNdviRgbVariabilitImage,
    setActiveRenderedNdviRgbVariabilitImage
  ] = React.useState(null);

  /**
   * delete selected Imgages from bottom carousal
   */
  useImperativeHandle(ref, () => ({
    getActiveNdviRgbVariabilitImages(image) {
      if (image?.shapeFileName) {
        props.showSelectedNdviRgbVariabilitImages(null);
        props.getSelectedShapeFileGeoJson(image, true);
      } else {
        props.showSelectedNdviRgbVariabilitImages(image.image);
        setActiveRenderedNdviRgbVariabilitImage(image.image.image_id);
      }
    },
    getDeletedShapeFileData(deletedShapeFile) {
      const fileData = shapeFiles.find((file) => file.name === deletedShapeFile.shapeFileName);
      if (deletedShapeFile.selectedAttribute === 'tiff') {
        let updateShapeFiles = shapeFiles.map((shapeFile) => {
          if (shapeFile.name === deletedShapeFile.shapeFileName) {
            delete shapeFile.shapeFileResponse;
          }
          return shapeFile;
        });
        setShapeFiles(updateShapeFiles);
        if (deletedShapeFile.shapeFileName === selectedElevation.shapeFileName) {
          setSelectedElevation({ ...selectedElevation, geoJson: null });
        }
        props.getSelectedShapeFileGeoJson(null);
      } else {
        getSelectedShapeFileAttribute(fileData, deletedShapeFile.selectedAttribute);
      }
    },
    getDeletedImage(deletedImage) {
      onSelectImage(deletedImage.image, deletedImage.year);
    },
    setStepperWhileError(stepVal) {
      setStepper(stepVal);
    }
  }));


  const [activeDropDownFile, setActiveDropDownFile] = React.useState(null);
  const getDropDownFileName = (eve, fileName) => {
    eve.stopPropagation();
    setActiveDropDownFile(fileName);
    setIsActiveImageListDropdown({});
  };

  /**
   * for dispalying the ImageListPopup.
   */
  const viewImageListDropdown = (event, year, imageData) => {
    setActiveDropDownFile('');
    event.stopPropagation();
    if (isActiveImageListDropdown.year && isActiveImageListDropdown.year === year.year) {
      setIsActiveImageListDropdown({});
      return;
    }
    if (year) {
      setIsActiveImageListDropdown({ year: year.year, month: imageData });
      return;
    }
    setIsActiveImageListDropdown({});
  };

  const stopPropagation = (eve) => {
    eve.stopPropagation();
  };
  const closeImageListPopup = () => {
    setIsActiveImageListDropdown({});
    setActiveDropDownFile('');
  };
  const isImgListToggleOnOff = (isToggelOnOff) => {
    setIsImgListToggleOn(isToggelOnOff);
    props.filterPopupData.isImgListToggleOn = isToggelOnOff;
    if (!isToggelOnOff) {
      setShowUploadSection(!isToggelOnOff);
    }
  };
  React.useEffect(() => {
    setSelectedImages(props.selectedImages);
  }, [props.selectedImages]);

  /** working in progress
   * Delete shape file functionality
   */
  const onShapeFileDelete = (selectedFile) => {
    const updatedShapeFiles = shapeFiles.filter(
      (fileData) => fileData.file.uid !== selectedFile.file.uid
    );
    if (selectedFile.selectedAttribute) {
      let attributeData = {
        selectedAttribute: selectedFile.selectedAttribute,
        geoJson: selectedFile.shapeFileResponse?.shapefile_geojson,
        shapeFileName: selectedFile.name
      };
      if (selectedFile.file.type === 'image/tiff') {
        attributeData.geoJson = selectedFile.shapeFileResponse?.geojson;
        props.onImageDelete(attributeData, false);
      } else {
        props.onImageDelete(attributeData, false);
      }
    }
    setShapeFiles(updatedShapeFiles);
  };
  const [selectedElevation, setSelectedElevation] = React.useState({
    selectedAttribute: 'tiff',
    geoJson: null,
    shapeFileName: null,
    isElevationFile: true
  });

  const { isElevation } = props.filterPopupData;
  return (
    <Button className='sidebar_filter' onClick={closeImageListPopup}>
      {iff(loader, <Loader />)}
      {iff(
        errorMessage ? true : false,
        <ErrorModal
          data-testid='error-msg'
          visible={errorMessage ? true : false}
          message={t(errorMessage)}
          okText={t('Ok')}
          onOk={() => {
            setErrorMessage(null);
          }}
        />
      )}
      <StyledDrawer
        title={props.selectedFieldItemName ? props.selectedFieldItemName : ''}
        placement='right'
        onClose={props.closeSideBar}
        closable={true}
        keyboard={false}
        maskClosable={false}
        visible={props.viewSidebar}>
        {stepper === 2 && (
          <div className='back-icon'>
            <StyledSpan style={{ cursor: 'pointer' }} onClick={backToGenerateZone} data-testid="back-arrow">
              <ArrowLeftOutlined
                style={{
                  marginRight: '5px',
                  cursor: 'pointer'
                }}
              />
              {t('Back')}
            </StyledSpan>
          </div>
        )}
        <Row>
          <Col span={24}>
            <div className='mzma-map_box_left mzma-p-t-10'>
              <div className='mzma-selected_fields'>
                <div className='mzma-border_bootom mzma-p-l-20'>
                  <div className='mzma_filter_Section'>
                    <span className='mzma-filter_icon' onClick={onModifyFilter}>
                      <span
                        className='filter_icon_stips'
                        style={{
                          width: '15px'
                        }}
                      />
                      <span
                        className='filter_icon_stips'
                        style={{
                          width: '10px',
                          margin: '2px 0px'
                        }}
                      />
                      <span
                        className='filter_icon_stips'
                        style={{
                          width: '5px'
                        }}
                      />
                    </span>
                    <StyledSpan data-testid="filter-images-label" className='mzma-filter_title'> {t('Filter Images')}</StyledSpan>
                    <StyledSwitchSection>
                      <Switch
                        checkedChildren={<CheckOutlined />}
                        unCheckedChildren={<CloseOutlined />}
                        defaultChecked={isImgListToggleOn}
                        checked={isImgListToggleOn}
                        size='small'
                        onChange={(event) => isImgListToggleOnOff(event)}
                        data-testid="filter-images-switch-arrow"
                      />
                    </StyledSwitchSection>
                  </div>
                  {!isImgListToggleOn && (
                    <div className='mzma-selected_date_source'>
                      <div className='mzma-selectd_source mzma-top_border'>
                        <StyledSpan data-testid="source-label">{t('Source')}</StyledSpan>
                        <StyledSpan data-testid="source-value">{selectedSource?.toString()}</StyledSpan>
                      </div>
                      <div className='mzma-selectd_date mzma-bottom_border'>
                        <StyledSpan data-testid="date-label">{t('Date')}</StyledSpan>
                        <StyledSpan data-testid="date-value">{selectedMonthYear}</StyledSpan>
                      </div>
                    </div>
                  )}
                </div>
                <div
                  className={
                    isElevation ? 'image_upload_section' : 'image_upload_section_withoutElevation'
                  }>
                  {!isImgListToggleOn && (
                    <div className='images_list_section'>
                      <StyledP data-testid="image-list-label" className='mzma-filter_title mzma-p-l-20 mzma-p-t-10'>
                        {t('IMAGE LIST')}
                      </StyledP>
                      <StyledDiv
                        className={
                          isElevation
                            ? showUploadSection && removeOutlierPixelErrorMsg
                              ? 'mzma-p-l-10 imaze_list_scroll dynamic_imaze_list_scroll_errorMsg'
                              : stepper === 2
                                ? 'mzma-p-l-10 imaze_list_scroll dynamic_imaze_list_scroll'
                                : !showUploadSection && zonesErrorMsg
                                  ? 'mzma-p-l-10 imaze_list_scroll dynamic_imaze_list_scroll_stepperOne_zone_errorMsg'
                                  : !showUploadSection
                                    ? 'mzma-p-l-10 imaze_list_scroll dynamic_imaze_list_scroll_stepperOne'
                                    : 'mzma-p-l-10 imaze_list_scroll'
                            : showUploadSection && removeOutlierPixelErrorMsg
                              ? 'mzma-p-l-10 imaze_list_scroll_withoutElevation dynamic_imaze_list_scroll_errorMsg_withoutElevatoin'
                              : stepper === 2
                                ? 'mzma-p-l-10 imaze_list_scroll_withoutElevation dynamic_imaze_list_scroll_withoutElevation'
                                : !showUploadSection && zonesErrorMsg
                                  ? 'mzma-p-l-10 imaze_list_scroll_withoutElevation dynamic_imaze_list_scroll_stepperOne_zone_errorMsg_withoutElevation'
                                  : !showUploadSection
                                    ? 'mzma-p-l-10 imaze_list_scroll_withoutElevation dynamic_imaze_list_scroll_stepperOne_withoutElevation'
                                    : 'mzma-p-l-10 imaze_list_scroll_withoutElevation'
                        }>
                        {filterResponse.assets?.map((year, index) => {
                          return (
                            <div style={{ position: 'relative' }}>
                              {year.imageList &&
                                Object.keys(year.imageList).map((imageData, indx) => {
                                  return (
                                    <div style={{ position: 'relative' }}>
                                      <div className='mzma-display'>
                                        <StyledImg
                                          // className={(year.imageList[imageData].find((data) => data.__isActiveImage)) ? 'activeImage' : ''}
                                          src={
                                            year.imageList[imageData].find(
                                              (data) => data.__isActiveImage
                                            )
                                              ? ImageSelectCheckbox
                                              : ImgIcon
                                          }
                                          data-testid="image-list-img"
                                        />
                                        <div className='mzma-display_image_item'>
                                          <StyledBoldP data-testid="image-list-date">
                                            {imageData.substring(0, 3) + ' ' + year.year}
                                          </StyledBoldP>
                                          <StyledImageListDropwodn
                                            data-testid={`${imageData.substring(0, 3)}-${year.year}`}
                                            onClick={(event) =>
                                              viewImageListDropdown(event, year, imageData)
                                            }>
                                            <DownOutlined />
                                            select
                                          </StyledImageListDropwodn>
                                          <StyledP className='mzma-textmsg_font' data-testid="image-list-para">
                                            {' '}
                                            {t('Select the appropriate image')}{' '}
                                          </StyledP>
                                        </div>
                                        <StyledP className='mzma-textmsg_font hover_text'>
                                          {t('Select the appropriate image from option shown')}
                                        </StyledP>
                                      </div>
                                      {isActiveImageListDropdown.year === year.year &&
                                        isActiveImageListDropdown.month === imageData && (
                                          <div
                                            className='mzma-image_list_popup'
                                            onClick={(eve) => stopPropagation(eve)}>
                                            <div className='mzma-source_popup_content'>
                                              <StyledRadioButtonPopup data-testid='select-field-list' className='mzma-source_selection_content'>
                                                {year.imageList[imageData].map(
                                                  (image, imageIndex) => (
                                                    <div
                                                      className='mzma-field_name mzma-p-l-10'
                                                      key={`${imageIndex}${image.image_id}`}>
                                                      <Label
                                                        key={`${imageIndex}${image.image_id}`}
                                                        className={
                                                          !image.image_id
                                                            ? 'mzma-radio_options disabledCursor'
                                                            : 'mzma-radio_options'
                                                        }>
                                                        {iff(
                                                          !image.image_id &&
                                                          image.__requestsCount < 20,
                                                          <StyledSpin
                                                            key={`${imageIndex}${image.image_id}`}
                                                            indicator={antIcon}
                                                          />,
                                                          <Radio
                                                            data-testid='select-field-image'
                                                            checked={image.__isActiveImage}
                                                            disabled={!image.image_id}
                                                            key={`${imageIndex}${image.image_id}`}
                                                            onClick={
                                                              !image.image_id
                                                                ? null
                                                                : () =>
                                                                  callRSSAPIForImage(
                                                                    image,
                                                                    year.year
                                                                  )
                                                            }></Radio>
                                                        )}
                                                        {image.__requestsCount >= 20 &&
                                                          !image.image_id
                                                          ? `${image.__acquisition_date_formatedLabel} (Image not available)`
                                                          : `${image.__acquisition_date_formatedLabel} (${image.source})`}
                                                      </Label>
                                                    </div>
                                                  )
                                                )}
                                              </StyledRadioButtonPopup>
                                            </div>
                                          </div>
                                        )}
                                    </div>
                                  );
                                })}
                            </div>
                          );
                        })}
                      </StyledDiv>
                      {filterResponse.assets && filterResponse.assets.length === 0 && (
                        <StyledP data-testid="no-images-found" className='no_images'> {t('No images found')}</StyledP>
                      )}
                    </div>
                  )}
                  {((showUploadSection && stepper === 1) || isImgListToggleOn) && (
                    <div className='mzma-selected_date_source mzma-upload_source '>
                      {!isImgListToggleOn && (
                        <DownCircleFilled
                          className='filled_downCircle'
                          onClick={() => setShowUploadSection(false)}
                        />
                      )}
                      <div className='mzma-selectd_upload mzma-upload_file'>
                        <Upload
                          {...uploadProps}
                          showUploadList={false}
                          className={
                            localStorage.getItem('session_id') ? 'nidera_scroll_them' : ''
                          }>
                          <StyledButton className='shapefile_btn' title={t('Select shape file')} data-testid="select-shape-file">
                            {t('Select shape file')}
                          </StyledButton>
                        </Upload>
                        <StyledDiv
                          className={
                            isElevation
                              ? !isImgListToggleOn
                                ? 'uploaded_listItems'
                                : zonesErrorMsg && isImgListToggleOn
                                  ? 'uploaded_listItems_fullheight_errorMsg'
                                  : removeOutlierPixelErrorMsg && isImgListToggleOn
                                    ? 'uploaded_listItems_outlier_errorMsg'
                                    : 'uploaded_listItems_fullheight'
                              : !isImgListToggleOn
                                ? 'uploaded_listItems_withoutElevation'
                                : zonesErrorMsg && isImgListToggleOn
                                  ? 'uploaded_listItems_fullheight_errorMsg_withoutElevation'
                                  : removeOutlierPixelErrorMsg && isImgListToggleOn
                                    ? 'uploaded_listItems_outlier_errorMsg_withourElevation'
                                    : 'uploaded_listItems_fullheight_withoutElevation'
                          }>
                          {shapeFiles &&
                            shapeFiles.length > 0 &&
                            shapeFiles.map((fileData) => {
                              return (
                                <div
                                  key={fileData.file.uid}
                                  className={
                                    fileData.name === selectedShapeFile.name
                                      ? 'parent_radio_setion active_shape_file'
                                      : 'parent_radio_setion'
                                  }>
                                  <Radio
                                    onClick={getSelectedShapeFile}
                                    disabled={
                                      uploading ||
                                      (fileData && !isEmpty(fileData.shapeFileResponse))
                                    }
                                    value={fileData.file.uid}>
                                    <img alt="" className='attach_icon' src={LinkIcon} />{' '}
                                    {fileData.file.name}
                                  </Radio>
                                  <div className='sub_radio_section'>
                                    {activeDropDownFile === fileData.name && (
                                      <Button
                                        className='mzma-image_list_popup attribute_popup_section'
                                        onClick={(eve) => stopPropagation(eve)}>
                                        <div className='mzma-source_popup_content'>
                                          <StyledAttributeSection className='mzma-source_selection_content attribute_selection_conten'>
                                            {fileData.shapeFileResponse?.attributes?.map(
                                              (attribute) => {
                                                return (
                                                  <div
                                                    key={attribute}
                                                    className='parent_radio_setion sub_attributeSection'>
                                                    <Radio
                                                      className='mzma-m-r-10'
                                                      name={fileData.name}
                                                      value={fileData.selectedAttribute}
                                                      checked={
                                                        fileData.selectedAttribute == attribute
                                                      }
                                                      id={attribute}
                                                      onClick={() =>
                                                        getSelectedShapeFileAttribute(
                                                          fileData,
                                                          attribute
                                                        )
                                                      }>
                                                      {attribute}
                                                    </Radio>
                                                  </div>
                                                );
                                              }
                                            )}
                                          </StyledAttributeSection>
                                        </div>
                                      </Button>
                                    )}
                                  </div>
                                  {fileData.file.type !== 'image/tiff' && (
                                    <React.Fragment>
                                      {activeDropDownFile === fileData.name && (
                                        <UpOutlined
                                          onClick={(eve) => getDropDownFileName(eve, '')}
                                        />
                                      )}
                                      {activeDropDownFile !== fileData.name &&
                                        fileData?.shapeFileResponse && (
                                          <DownOutlined
                                            onClick={(eve) =>
                                              getDropDownFileName(eve, fileData.name)
                                            }
                                          />
                                        )}
                                    </React.Fragment>
                                  )}
                                  <CloseCircleOutlined
                                    className='carosel_delete_image delete_image'
                                    onClick={(eve) => {
                                      eve.stopPropagation()
                                      onShapeFileDelete(fileData);
                                    }}
                                  />
                                </div>
                              );
                            })}
                          {/* </Radio.Group> */}
                          {shapeFileError !== null && (
                            <div className='error_tip shapeFile_error'>
                              <img alt="" className='mzma-img_size' src={TipIcon} />
                              <StyledError data-testid='error-shapeFile-msg'>{t(shapeFileError)}</StyledError>
                            </div>
                          )}
                        </StyledDiv>
                        <StyledButton
                          type='primary'
                          style={{ position: 'absolute' }}
                          title={uploading ? t('Uploading') : t('Upload shape file')}
                          onClick={handleUpload}
                          disabled={shapeFiles.length === 0}
                          loading={uploading}
                          className='upload_shape_file_btn shapefile_btn'
                          data-testid="upload-shape-file">
                          {uploading ? t('Uploading') : t('Upload shape file')}
                        </StyledButton>
                      </div>
                    </div>
                  )}
                </div>
              </div>
            </div>
          </Col>
        </Row>
        <div
          className={
            !showUploadSection && zonesErrorMsg
              ? 'export_Remove_section_zone_errorMsg'
              : showUploadSection || stepper === 2
                ? 'export_Remove_section border_none '
                : 'export_Remove_section'
          }>
          {!showUploadSection && stepper === 1 && (
            <UpCircleFilled
              className='filled_upCircle'
              onClick={() => setShowUploadSection(true)}
            />
          )}
          {stepper === 1 && (
            <div
              className={
                !showUploadSection && zonesErrorMsg
                  ? 'export_Remove_btns_zone_errorMsg'
                  : 'export_Remove_btns'
              }>
              <div className='num_zones'>
                <StyledInput
                  type='text'
                  className={
                    zonesErrorMsg?.includes('Zone')
                      ? 'num_zone_input outlayerPixe_error'
                      : 'num_zone_input'
                  }
                  placeholder={t('No.of zones')}
                  onChange={getZoneNumber}
                  value={zoneNumber}
                  data-testid="no-of-zone-input"
                />
                {zonesErrorMsg && (
                  <div className='error_tip'>
                    <img alt="" className='mzma-img_size' src={TipIcon} />
                    <StyledError data-testid="validations-error">{t(zonesErrorMsg)}</StyledError>
                  </div>
                )}
              </div>
              <StyledButton
                title={t('Generate zone')}
                data-testid="regenerates-btn"
                style={{ marginRight: '7px' }}
                onClick={onCreateZones}>
                {t('Generate zone')}
              </StyledButton>
            </div>
          )}
          {stepper === 2 && (
            <div>
              <div className='export_Remove_btns'>
                <div className='num_zones'>
                  <StyledInput
                    type='text'
                    className={
                      removeOutlierPixelErrorMsg
                        ? 'num_zone_input outlayerPixe_error'
                        : 'num_zone_input'
                    }
                    data-testid="no-of-outerPixel-input"
                    placeholder={t('Number of pixel')}
                    onChange={getRemoveOutlierPixel}
                    value={removeOutlierPixel}
                  />

                  {stepper === 2 && removeOutlierPixelErrorMsg && (
                    <div className='mzma-m-t-neg50 error_tip '>
                      <img alt="" className='mzma-img_size' src={TipIcon} />
                      <StyledError data-testid="validations-error1">{t(removeOutlierPixelErrorMsg)}</StyledError>
                    </div>
                  )}
                </div>
                <Tooltip
                  title={t(
                    'Delete small areas within larger areas. The value entered is the minimum area size threshold.'
                  )}
                  placement='topRight'
                  trigger='hover'>
                  <StyledButton
                    className='shapefile_btn'
                    title={t('Remove outlier')}
                    style={{ marginRight: '7px' }}
                    data-testid="remove-OutlierPixel"
                    onClick={onRemoveOutlierPixel}>
                    {t('Remove outlier')}
                  </StyledButton>
                </Tooltip>
              </div>
              <div className='export_Remove_btns exort_btn_group'>
                <StyledButton data-testid="download-Zones-btn" onClick={downloadZonesData}>{t('Download')}</StyledButton>
              </div>
            </div>
          )}
        </div>
      </StyledDrawer>
    </Button >
  );
});

SideBarFilterModel.propTypes = {
  onCancel: PropTypes.func,
  showSelectedNdviRgbVariabilitImages: PropTypes.func,
  createZones: PropTypes.func,
  selectedFieldItemName: PropTypes.string.isRequired,
  selectedFieldItem: PropTypes.any.isRequired,
  viewSidebar: PropTypes.bool.isRequired,
  filterPopupData: PropTypes.shape({
    selectedYear: PropTypes.string.isRequired,
    selectedSource: PropTypes.arrayOf(PropTypes.string).isRequired,
    selectedDate: PropTypes.any.isRequired,
    isImgListToggleOn: PropTypes.bool.isRequired,
    selectedEndDate: PropTypes.object,
    isElevation: PropTypes.bool
  }),
  filterResponseData: PropTypes.shape({
    geom_id: PropTypes.string,
    assets: PropTypes.arrayOf(PropTypes.object)
  }),
  getSelectedShapeFileGeoJson: PropTypes.func,
  onImageDelete: PropTypes.func,
  selectedImages: PropTypes.array,
  showFilterPopup: PropTypes.func,
  getSelectedImages: PropTypes.func,
  downloadZonesData: PropTypes.func,
  closeSideBar: PropTypes.func
};
