import {
  TOGGLE_HEADER,
} from '../actions/types';

const initialState = {
  collapsed: true,
  selectedOrganization: null,
  selectedProperty: null
};

export const header = (state = initialState, action) => {
  switch (action.type) {
    case TOGGLE_HEADER:
      return {
        ...state,
        collapsed: (action.payload !== null && action.payload) || !state.collapsed
      };
    default:
      return state;
  }
};
