import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import styled from 'styled-components';
import { Drawer, Divider } from 'antd/lib';
import { connect } from 'react-redux';
import { withRouter } from 'react-router';
import get from 'lodash/get';
import { clearAccessToken } from '../../utils/common-methods';
import { AmplitudeInstance } from '../../utils/amplitude';

import {
  getOrganizations,
  getPropertiesByOrgId,
  getNoOfOrgs
} from '../../selectors/organizationSelectors';

import { toggleHeader } from '../../actions/index';

import { NewHeader } from './newComponent';
import { CollapsedHeader } from './collapsedHeader';
import { iff } from '../../utils/iff';
import { fetchUserProfile } from '../../actions/user';
import { URL_CONSTANTS } from '../../utils/history';

const StyledDiv = styled.div`
  position: absolute;
  top: 181px;
  z-index: 2000;
  .anticon-up-circle {
    color: ${(props) => get(props, 'theme.colors.scrollbarThumbBgColor')} !important;
  }
  .ant-divider-horizontal.ant-divider-with-text-center::after,.ant-divider-horizontal.ant-divider-with-text-center::before{
    border-top:none
  }
`;

const StyledDrawer = styled(Drawer)`
  .ant-drawer-body {
    padding: 0 5px;
  }
  /* .ant-drawer-wrapper-body {
    overflow: hidden;
  } */
  .ant-drawer-content-wrapper {
    height: 200px;
  }
  .ant-drawer-content-wrapper > .ant-drawer-content > .ant-drawer-wrapper-body {
    overflow: hidden;
  }
  .ant-drawer-content-wrapper > * ::-webkit-scrollbar {
    width: 3px;
  }
  .ant-drawer-content-wrapper > * ::-webkit-scrollbar-track {
    background: transparent;
    border-top: 2px solid transparent;
    border-bottom: 2px solid transparent;
    border-right: 2px solid transparent;
    border-left: 2px solid transparent;
  }
`;
import HeaderArrow from '../../public/Header_arrow.svg';
import MzmaHeaderArrow from '../../public/Mzma_Header_Arrow.svg';
export const StyledArrowDiv = styled.div`
    display: flex;
    justify-content: center;
    position: relative;
    .hearder_downArrow{
    position: absolute;
    z-index: 999;
    background: white;
    padding: 6px;
    border-radius: 50%;
    top: -15px;
    cursor: pointer;
    transform: rotateX(180deg);
    }
`;
const HeaderContainer = (props) => {
  const navigateByOrgId = (id) => {
    props.toggleHeader(true);
    props.history.push(URL_CONSTANTS.LANDING_ORGANIZATION({ orgId: id }));
  };

  const navigateByPropertyId = (organizationId, propertyId) => {
    props.toggleHeader(true);
    if (props.setSelectedImges) {
      props.setSelectedImges([]);
    }
    props.history.push(URL_CONSTANTS.LANDING_FARM({ orgId: organizationId, farmId: propertyId }));
  };

  const handleLogoClick = () => {
    props.toggleHeader(true);
    if (props.noOfOrgs) {
      props.history.push(URL_CONSTANTS.LANDING_ORGANIZATION({ orgId: props.orgs[0].id }));
    }
  };

  const handleLogout = () => {
    AmplitudeInstance.onLogout();
    clearAccessToken();
    window.localStorage.clear();
    window.location.href = '/';
  };

  useEffect(() => {
    props.fetchUserProfile();
  }, []);

  return (
    <>
      <CollapsedHeader
        {...props}
        navigateByOrgId={navigateByOrgId}
        navigateByPropertyId={navigateByPropertyId}
        onLogoClick={handleLogoClick}
        handleLogout={handleLogout}
      />

      <StyledDrawer
        className='headerDrawer'
        closable={false}
        height={200}
        placement='top'
        maskClosable={true}
        onClose={() => props.toggleHeader(true)}
        visible={!props.isCollapsed}>
        {iff(
          !!props.header,
          props.header,
          <NewHeader
            {...props}
            navigateByOrgId={navigateByOrgId}
            navigateByPropertyId={navigateByPropertyId}
            handleLogout={handleLogout}
            onLogoClick={handleLogoClick}
          />
        )}
      </StyledDrawer>
      {iff(
        !props.isCollapsed,
        <StyledDiv>
          <Divider style={{ margin: 0, borderTop: "none" }}>
            <StyledArrowDiv>
              <input alt="" type="image"
                className="hearder_downArrow"
                style={{ height: '25px', width: '25px' }}
                src={localStorage.getItem('session_id') ? HeaderArrow : MzmaHeaderArrow}
                onClick={() => props.toggleHeader(false)}
              />
            </StyledArrowDiv>
          </Divider>
        </StyledDiv>
      )}
    </>
  );
};

HeaderContainer.propTypes = {
  orgs: PropTypes.array.isRequired,
  properties: PropTypes.array.isRequired,
  orgId: PropTypes.string,
  propertyId: PropTypes.string,
  toggleHeader: PropTypes.func.isRequired,
  isCollapsed: PropTypes.bool.isRequired,
  header: PropTypes.node,
  history: PropTypes.object,
  noOfOrgs: PropTypes.number,
  fetchUserProfile: PropTypes.func.isRequired,
  userProfile: PropTypes.object.isRequired
};

const mapStateToProps = (state, ownProps) => {
  const orgId = get(ownProps, 'match.params.id', null);
  const properties = getPropertiesByOrgId(state, { orgId: orgId });
  const propertyId = get(ownProps, 'match.params.farmId', null);

  const noOfOrgs = getNoOfOrgs(state);

  return {
    orgs: getOrganizations(state),
    properties,
    orgId,
    propertyId,
    noOfOrgs,
    userProfile: state.user.userProfile
  };
};

const mapDispatchToProps = (dispatch) => ({
  toggleHeader: (status) => dispatch(toggleHeader(status)),
  fetchUserProfile: () => dispatch(fetchUserProfile())
});
export const HeaderContainerComponent = withRouter(
  connect(mapStateToProps, mapDispatchToProps)(HeaderContainer)
);
