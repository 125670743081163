import React from 'react';
import { Modal } from 'antd/lib';
import styled from 'styled-components';
import { useTranslation } from 'react-i18next';
import PropTypes from 'prop-types';
import { iff } from '../../../utils/iff';
import get from 'lodash/get';
import { ButtonFontFamily } from '../../../utils/styles.theming';

const StyledModal = styled(Modal)`
  .ant-modal-header {
    padding: 16px 24px;
    color: rgba(0, 0, 0, 0.65);
    background: #fff;
    border-bottom: 3px solid #8bc34a;
    border-radius: 4px 4px 0 0;
  }
  .ant-modal-footer {
    border-top: none;
  }
  .ant-modal-title {
    color: #8bc34a !important;
    font-family: ${(props) => get(props, 'theme.font.family')};
  }
  .ant-btn-primary {
    color: #fff;
    background-color: #8bc34a !important;
    border-color: #8bc34a !important;
  }
`;

const Styledp = styled.p`
  font-family: ${(props) => get(props, 'theme.font.family')};
  font-style: normal;
  font-weight: normal;
  font-size: 16px;
  line-height: 19px;
  color: #2f3031;
`;

export const SuccessModal = (props) => {
  const { t } = useTranslation();
  return (
    <div>
      <StyledModal
        title={t('Success')}
        visible={props.visible}
        closable={false}
        width={400}
        footer={[
          <>
            {iff(
              props.cancelText !== undefined,
              <ButtonFontFamily data-testid='OkBtn' key={1} type='primary' onClick={props.onCancel}>
                {props.cancelText}
              </ButtonFontFamily>
            )}
            <ButtonFontFamily data-testid='RedirectBtn' key={1} type='primary' onClick={props.onOk}>
              {props.okText}
            </ButtonFontFamily>
          </>
        ]}>
        <Styledp>{props.message}</Styledp>
      </StyledModal>
    </div>
  );
};
SuccessModal.propTypes = {
  visible: PropTypes.bool,
  message: PropTypes.string,
  okText: PropTypes.string,
  onOk: PropTypes.func,
  cancelText: PropTypes.string,
  onCancelClick: PropTypes.func
};
