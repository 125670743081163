import styled from 'styled-components';
import get from 'lodash/get';
import {iff} from '../../../utils/iff';

export const Container = styled.div`
  box-shadow: 0 4px 8px 0 rgba(0, 0, 0, 0.2);
  transition: 0.3s;
  height: ${props => iff (props.propertiesType === 'property', '144px', '192px')};
  width:30%;
  background-color: ${props => get (props, 'theme.colors.propertiesCardBgColor')};
  border-radius: 16px;
  margin: 20px;
  margin-left: 0px;
  display:inline-block;
  @media (min-width: 1714px) {
    width:20%;
  }
  &:hover {
    box-shadow: 0px 2px 14px rgba(37, 60, 83, 0.16);
    border: 1px solid ${props => get (props, 'theme.colors.propertiesCardBgColor')};
  }
  .top-section {
    cursor: pointer;
    padding: 15px 20px;
    border-radius: 5px 5px 0 0;
    width: 100%;
    height: 144px;
    border-radius: 16px;
    background-color: ${props => get (props, 'theme.colors.pageBackgroundPrimary')};
    position: relative;

    .title-bar {
      font-weight: 600;
      margin: 10px 0;
      .left-caret {
        color: #707374;
        margin-left: 10px;
      }
      width: 100%;
      overflow: hidden;
      text-overflow: ellipsis;
      white-space: nowrap;
      color: #2f3031;
    }
    .details {
      margin-top: 30px;
    }
    .rectangle {
      position: absolute;
      top: 5px;
      left: 0;
      margin-top: 10px;
      background: ${props => get (props, 'theme.colors.rectangleBgColor')};
      border-radius: 0 8px 8px 0;
      height: 50px;
      width: 5px;
    }
    img {
      margin-right: 10px;
    }
  }
  .full-top-section {
    height: 100%;
  }

  .bottom-section {
    display: flex;
    align-items: center;
    justify-content: center;
    height: 48px;
    width: 100%;
    border-bottom-left-radius: 16px;
    border-bottom-right-radius: 16px;
    background-image: ${props => get (props, 'theme.colors.propertiesCardBgImage')};
    cursor: pointer;
    img {
      margin-right: 10px;
    }
    &:hover {
      font-weight: 500;
      background: ${props => get (props, 'theme.colors.propertiesCardBgColor')};
    }
    .edit-section {
      color: ${props => get (props, 'theme.colors.pageBackgroundPrimary')};
      &:hover {
        font-weight: 500;
        background: ${props => get (props, 'theme.colors.propertiesCardBgColor')};
      }
    }
  }
  .delete_icon {
    position: absolute;
    right: 18px;
    font-size: 17px;
    top: 11px;
    cursor: pointer;
    &:hover {
      color: red;
    }
  }
`;
