import React, { useEffect, useState, useRef } from 'react';
import PropTypes from 'prop-types';
import { connect } from 'react-redux';
import get from 'lodash/get';
import styled from 'styled-components';
import { Button, Carousel, Tooltip } from 'antd/lib';
import { useTranslation } from 'react-i18next';
import { Maps } from './Maps';
import { getSelectedProperty } from '../../selectors/organizationSelectors';
import { PageLayout } from '../Layout/page-layout';
import { fetchOrgs } from '../../actions/index';
import { getFieldsByPropertyId } from '../../actions/fields';
import { getHeaders } from '../../utils/common-methods';
import { iff } from '../../utils/iff';
import { request } from '../../utils/axios';
import { Loader } from '../Loader/Loader';
import { FieldListPopup } from './fieldListPopup/FieldListPopup';
import { CreateBoundaryModal } from '../common/Modal/CreateBoundaryModal';
import { FilterPopup } from '../common/Modal/filterPopup';
import { BoxPlot } from '../common/Modal/BoxPlot';
import { SideBarFilterModel } from '../common/Modal/sidebarFilterModel';
import { NewSideBarFilterModel } from '../common/NewUI/NewSidebarFilterModel';
import '../common/Modal/filterModel.css';
import { AmplitudeInstance } from '../../utils/amplitude';
import { ErrorModal } from '../common/Modal/ErrorModal';
import { SuccessModal } from '../common/Modal/successModal';
import { LeftOutlined, RightOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { history, URL_CONSTANTS } from '../../utils/history';
const ShapefileIcon = require('../../public/shapeFile_icon.svg');
import moment from 'moment-timezone';
import { AppConstants } from '../../utils/app.constants';
const DEFAULT_LAT_LONG = 10;

export const StyledAddNewFieldSection = styled.div`
  font-family: ${(props) => get(props, 'theme.font.family')};
  position: fixed;
  z-index: 999;
  top: 13%;
  left: 8.7%;
  transform: translateX(-50%);
  height: 40px;
  width: 143px;
  border-radius: 4px;
`;

const StyledNDVIRGBButtons = styled.div`
  font-family: ${(props) => get(props, 'theme.font.family')};
  position: absolute;
  z-index: 999;
  top: 10px;
  transform: translateX(-50%);
  height: 40px;
  border-radius: 4px;
`;
const StyledVariabilityButtons = styled.div`
  font-family: ${(props) => get(props, 'theme.font.family')};
  position: absolute;
  z-index: 999;
  top: 10px;
  transform: translateX(-50%);
  height: 40px;
  border-radius: 4px;
  color: ${(props) => get(props, 'theme.colors.butonColor')};
  height: 40px;
  border: none;
  background: ${(props) => get(props, 'theme.colors.buttonBackground')};
  &:hover {
    background: ${(props) => get(props, 'theme.colors.buttonHoverBgColor')};
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
    border: ${(props) => get(props, 'theme.colors.buttonHoverBorderColor')};
  }
`;

export const StyledManageMentZoneFieldSection = styled.div`
  font-family: ${(props) => get(props, 'theme.font.family')};
  position: absolute;
  z-index: 999;
  top: 13%;
  right: -35px;
  transform: translateX(-50%);
  border-radius: 4px;
  &:hover span {
    display: block;
  }
`;
const StyledButton = styled(Button)`
  font-family: ${(props) => get(props, 'theme.font.family')};
  background: ${(props) => get(props, 'theme.colors.buttonBackground')};
  color: ${(props) => get(props, 'theme.colors.butonColor')};
  height: 40px;
  border: none;
  &:hover {
    background: ${(props) => get(props, 'theme.colors.buttonHoverBgColor')};
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
    border: ${(props) => get(props, 'theme.colors.buttonHoverBorderColor')};
  }
`;

const VariabilityButton = styled(Button)`
  font-family: ${(props) => get(props, 'theme.font.family')};
  background: none;
  color: ${(props) => get(props, 'theme.colors.butonColor')};
  height: 40px;
  width: 142px;
  border: none;
  &:hover {
    background: none;
    color: ${(props) => get(props, 'theme.colors.buttonHoverColor')};
    border: none;
  }
`;

const StyledInfo = styled.span`
  font-family: ${(props) => get(props, 'theme.font.family')};
  position: fixed;
  top: 50px;
  right: 0px;
  color: black;
  background-color: white;
  padding: 6px;
  width: 146px;
  border-radius: 7px;
  display: none;
`;

export const StyledButtonSection = styled.div`
  display: flex;
  justify-content: flex-end;
  position: absolute;
  width: 100%;
  bottom: 17px;
  left: 0px;
  display: flex;
  z-index: -1;
  justify-content: space-between;
  padding: 0px 10px;
`;
export const StyledArrowButton = styled(Button)`
  height: 31px;
  width: 31px;
  border-radius: 50%;
  color: #ffd100;
  display: flex;
  align-items: center;
  justify-content: center;
  color: ${(props) => get(props, 'theme.colors.primary')};
  &:hover {
    border: 1px solid ${(props) => get(props, 'theme.colors.primary')};
    color: ${(props) => get(props, 'theme.colors.primary')};
  }
  &:active {
    border-color: ${(props) => get(props, 'theme.colors.primary')};
    color: ${(props) => get(props, 'theme.colors.primary')};
  }
  &:focus {
    border-color: ${(props) => get(props, 'theme.colors.primary')};
    color: ${(props) => get(props, 'theme.colors.primary')};
  }
  .anticon {
    font-size: 16px;
  }
`;
let activeId;
const MapsContainer = (props) => {
  const [lat] = useState(DEFAULT_LAT_LONG);
  const [long] = useState(DEFAULT_LAT_LONG);
  const [allFields, setAllFields] = useState([]);
  const [isDataLoading, setIsDataLoading] = useState(true);
  const [isPropertyLoading, setIsPropertyLoading] = useState(false);
  const [addNewFieldPopup, setAddNewFieldPopup] = useState(false);
  const [filterPopup, setFilterPopup] = useState(false);
  const [viewSidebar, setViewSidebar] = useState(false);
  const [viewSelectedImgRes, setViewSelectedImgRes] = useState(false);
  const [selectedFieldItem, setSelectedFieldItem] = useState({});
  const [selectedZoneRes, setSelectedZoneRes] = useState({});
  const [selectedRegenerateZoneAPIResponse, setSelectedRegenerateZoneAPIResponse] = useState({});
  const [loader, setLoader] = useState(false);
  const [filterResponseData, setFilterResponseData] = useState({});
  const [filterPopupData, setFilterPopupData] = useState({
    selectedSource: ['sentinel'],
    selectedDate: '',
    selectedYear: '2017',
    selectedEndDate: '',
    maxEndDate: '',
    cloudCoverValue: 1,
    isImgListToggleOn: false,
    isElevation: false
  });
  const [clearMap, setClearMap] = useState(false);
  const [colorMultiplier, setColorMultiplier] = useState(1);
  const { t } = useTranslation();
  const [activeImage, setActiveImage] = useState('NDVI');
  const [selectedNdviRgbVariabilitImages, setSelectedNdviRgbVariabilitImages] = useState(null);
  const [errorMessage, setErrorMessage] = useState('');
  const [successMessage, setSuccessMessage] = useState('');
  const sideBarFilterModelRef = useRef();
  const [selectedPropertyName, setSelectedPropertyName] = useState(null);
  const [isNewSidebarVisible, setIsNewSidebarVisible] = useState(false);
  const [boxPlot, setBoxPlot] = useState(false);
  const [imageSelected, setImageSelected] = useState({});
  const [productivityZonesAPIResponse, setProductivityZonesAPIResponse] = useState({});
  const [disableAutomaticButton, setDisableAutomaticButton] = useState(true);
  const [showTimeLimitDisclaimer, setShowTimeLimitDisclaimer] = useState(false);
  const [selectedPropertyTimeZone, setSelectedPropertyTimeZone] = useState(null);
  const [selectedImages, setSelectedImages] = useState([]);

  const getPropDetails = async () => {
    try {
      setIsPropertyLoading(true);
      const property = await request.get(`/v2/properties/${props.match.params.farmId}`, {
        headers: getHeaders()
      });
      setSelectedPropertyName(property.data.name);
      setSelectedPropertyTimeZone(property.data.time_zone);
    } catch (error) {
      setIsPropertyLoading(false);
      if (error?.response?.data?.message) {
        setErrorMessage(error.response.data.message);
      } else {
        setErrorMessage('Something went wrong');
      }
      console.log(error.response);
    }
    setIsPropertyLoading(false);
  };

  const getAllFields = async () => {
    const propertyRes = await getFieldsByPropertyId(props.match.params.farmId);
    setAllFields(propertyRes.data.content);
    getPropDetails();
  };

  useEffect(() => {
    getAllFields();
    setViewSelectedImgRes(false);
    setSelectedNdviRgbVariabilitImages(null);
    setViewSidebar(false);
    setSelectedFieldItem({});
    setFilterPopupData({
      sselectedSource: ['sentinel'],
      selectedDate: '',
      selectedYear: '2017',
      selectedEndDate: '',
      maxEndDate: '',
      cloudCoverValue: 1,
      isImgListToggleOn: false,
      isElevation: false
    });
  }, [props.match]);

  useEffect(() => {
    console.log('process.env.envName:', process.env.envName);
    getPropDetails();
    Promise.all([props.fetchOrgs()]).then((success) => {
      setIsDataLoading(false);
    });
  }, []);

  useEffect(() => {
    if (selectedFieldItem) {
      setViewSidebar(false);
      setFilterPopupData({
        selectedSource: ['sentinel'],
        selectedDate: '',
        selectedYear: '2017',
        selectedEndDate: '',
        maxEndDate: '',
        cloudCoverValue: 1,
        isImgListToggleOn: false,
        isElevation: false
      });
    }
  }, [selectedFieldItem]);

  let closeSideBarTimer = null;
  const handleFieldClick = (id) => {
    setDisableAutomaticButton(true);
    if (id) {
      if (activeId !== id) {
        setTimeout(() => {
          closeSideBar();
          closeSideBarTimer = null;
        }, 500);
      }
      activeId = id;
      const recentlySelectedField = allFields.find((fieldItem) => fieldItem.id === id);
      if (recentlySelectedField) {
        let showDisclaimer = isWithinTimeLimit(recentlySelectedField.updated_at);
        if (showDisclaimer) {
          setShowTimeLimitDisclaimer(true);
        } else {
          setDisableAutomaticButton(false);
        }
        setSelectedFieldItem(recentlySelectedField);
      }
    }
  };

  const isWithinTimeLimit = (updatedTime) => {
    try {
      let loggedInUserTimestamp = moment.utc();
      let loggedInUserTimestampInZone = moment.tz(loggedInUserTimestamp, selectedPropertyTimeZone);
      const fieldCreatedTime = moment.tz(updatedTime, selectedPropertyTimeZone);
      let differenceInMinutes = loggedInUserTimestampInZone.diff(fieldCreatedTime, 'minutes');
      console.log('differenceInHours', differenceInMinutes);
      if (isNaN(differenceInMinutes)) {
        setErrorMessage(t('Invalid date value'));
        return;
      }
      // set time as 2 Hrs === 120 Minutes
      return differenceInMinutes <= 120;
    } catch (error) {
      setErrorMessage(t('Something went wrong'));
    }
  };

  const handleShowAddNewFieldPopup = () => {
    setAddNewFieldPopup(true);
  };

  const handleCancelAddNewFieldPopup = () => {
    setAddNewFieldPopup(false);
  };
  const showFilterPopup = () => {
    setFilterPopup(true);
  };

  const showBoxPlot = () => {
    setBoxPlot(true);
  };

  const cancelFilterPopup = () => {
    setFilterPopup(false);
  };
  const cancelShowBoxPlot = () => {
    setBoxPlot(false);
  };

  const handleShapeFileName = (data, isNotTiffFile) => {
    let displayName;

    if (data.shapeFileName) {
      if (isNotTiffFile) {
        displayName = `${data.shapeFileName} (${data.selectedAttribute})`;
      } else {
        displayName = `${data.shapeFileName}`;
      }
    } else {
      displayName = new Date(data.image.acquisition_date).toDateString();
    }
    return displayName;
  };

  const [elevationData, setElevationData] = React.useState([]);
  const [elevationErrorMessage, setElevationErrorMessage] = React.useState(null);

  const removeOnlyImgeListOnFilter = () => {
    const shapeFileData = selectedImages?.filter((imageData) => {
      if (imageData.selectedAttribute && !imageData.isElevationFile) {
        return imageData;
      }
    });
    setSelectedImages(shapeFileData);
  };
  /**
   * On clicking the Apply button in Filter Popup
   * @param {Object}filterData
   */
  const onApplyFilter = (filterData) => {
    let errorLength = filterData.validateFilterPopup('All');
    if (errorLength === 0) {
      removeOnlyImgeListOnFilter();
      setElevationData([]);
      setElevationErrorMessage(null);
      let start_month = moment(filterPopupData.selectedDate).month() + 1;
      let start_year = moment(filterPopupData.selectedDate).year();
      let end_year = moment().year();
      let end_month = moment(filterPopupData.selectedEndDate).month() + 1;
      let payload = {
        field_id: selectedFieldItem.id,
        filters: {
          start_year: start_year,
          end_year: end_year,
          start_month: start_month,
          end_month: end_month,
          max_cloud_coverage: filterPopupData.cloudCoverValue / 100
        }
      };

      setFilterResponseData({});
      setSelectedNdviRgbVariabilitImages(null);
      setClearMap(!clearMap);
      setLoader(true);
      AmplitudeInstance.onApplyFilter(payload);
      if (!filterPopupData.isImgListToggleOn) {
        request
          .post('/list_images', payload, {
            baseURL: process.env.MZ_API_URL,
            headers: getHeaders()
          })
          .then((response) => {
            if (response?.data) {
              setFilterPopup(false);
              setViewSidebar(true);
            }
            setLoader(false);
            return response.data;
          })
          .then((res) => {
            getSessionToken(res);
          })
          .catch((error) => {
            console.log('error--', error);
            removeOnlyImgeListOnFilter();
            setFilterPopup(false);
            setLoader(false);
            if (error?.response?.data?.message && error.response.data.status === 500) {
              setErrorMessage(error.response.data.message);
            } else {
              setErrorMessage(
                t('We are downloading the images for your field please try in a couple of minutes')
              );
            }
          });
      } else {
        setLoader(false);
        setViewSidebar(true);
      }
    }
  };
  const getSessionToken = (responseData) => {
    setLoader(true);
    console.log('data', responseData);
    let authHeader = getHeaders();
    request
      .get('/session/signature', {
        baseURL: process.env.MZ_RSS_API_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader.common.Authorization
        }
      })
      .then((response) => {
        const sessionToken = response.data.session_token;
        if (responseData?.images) {
          responseData.images.map((data) => {
            let ndvi_url = `${process.env.MZ_RSS_API_URL}/images/${data.image_id}/ndvi/falsecolor/tiles/{z}/{x}/{y}/png?session_token=${sessionToken}`;
            let rgb_url = `${process.env.MZ_RSS_API_URL}/images/${data.image_id}/rgb/truecolor/tiles/{z}/{x}/{y}/png?session_token=${sessionToken}`;
            let variability_view_url = `${process.env.MZ_RSS_API_URL}/images/${data.image_id}/ndvi/falsecolor/tiles/{z}/{x}/{y}/png?variability=0.6&session_token=${sessionToken}`;
            let imageToURL_Dict = [];
            let payload = {
              ndvi_image_url: ndvi_url,
              png_image_url: rgb_url,
              variability_view_url: variability_view_url
            };
            imageToURL_Dict.push(payload);
            data.image_urls = imageToURL_Dict;
            data.fetch_image_again = false;
            return data;
          });
          setFilterResponseData(responseData);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        setErrorMessage('some error in session token');
        console.log('error:', error);
      });
  };
  const showSelectedNdviRgbVariabilitImages = (selectedNdviRgbVariabilitImages) => {
    if (selectedNdviRgbVariabilitImages === null) {
      setSelectedNdviRgbVariabilitImages(null);
    } else {
      setClearMap(!clearMap);
      setViewSelectedImgRes(true);
      setActiveImage('NDVI');
      setSelectedNdviRgbVariabilitImages({ ...selectedNdviRgbVariabilitImages });
    }
  };

  const handleReGenerateZones = () => {
    setLoader(true);
    request
      .get('/interval_suggestion', {
        baseURL: process.env.MZ_PROD_ZONE_API_URL,
        headers: getHeaders(),
        params: {
          field_id: selectedFieldItem.id,
          start_date: AppConstants.DATES.START_DATE,
          end_date: AppConstants.DATES.END_DATE,
          max_cloud_percentage: 0.1,
          remove_low_relevance_images: true
        }
      })
      .then((response) => {
        setIsNewSidebarVisible(true);
        setImageSelected(response.data.content);
        setLoader(false);
        return response.data.content.history;
      })
      .then((val) => {
        getproductivityZonesAPIResponse(val);
      })
      .catch((error) => {
        setLoader(false);
        if (error?.response?.data?.message && error.response.data.status === 500) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage(
            t('We are downloading the images for your field please try in a couple of minutes')
          );
        }
        setIsNewSidebarVisible(false);
        console.log(error);
      });
  };

  const getproductivityZonesAPIResponse = (val, zone, outlier) => {
    setLoader(true);
    let authHeader = getHeaders();
    let payload = {
      field_id: selectedFieldItem.id,
      history: val
    };
    request
      .post('/productivity_zones', payload, {
        baseURL: process.env.MZ_PROD_ZONE_API_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader.common.Authorization
        },
        params: {
          n_zones: zone,
          min_area: outlier || 100
        }
      })
      .then((response) => {
        setLoader(false);
        setProductivityZonesAPIResponse(response);
        return response.data.content;
      })
      .then((val) => {
        setLoader(true);
        setColorMultiplier(15 / val.features.length);
        setClearMap(!clearMap);
        setSelectedRegenerateZoneAPIResponse(val);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        if (error?.response?.data?.message && error.response.data.code === 400) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('Please try Again');
        }
      });
  };

  const handleCreateZones = (payload, eventType) => {
    payload.field_id = selectedFieldItem.id;
    setColorMultiplier(15 / payload.zone);
    setLoader(true);
    request
      .post('/cluster', payload, {
        baseURL: process.env.MZ_API_URL,
        headers: getHeaders()
      })
      .then((response) => {
        sideBarFilterModelRef.current.setStepperWhileError(2);
        setSelectedNdviRgbVariabilitImages(null);
        setClearMap(!clearMap);
        setSelectedZoneRes(response.data);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        if (error?.response?.data?.message && error.response.data.code === 400) {
          if (eventType === 'onRemoveOutlier') {
            setErrorMessage(error.response.data.message);
            sideBarFilterModelRef.current.setStepperWhileError(2);
          } else {
            setErrorMessage(error.response.data.message);
            sideBarFilterModelRef.current.setStepperWhileError(1);
          }
        } else {
          setErrorMessage('Something went wrong');
        }
      });
  };

  const downloadZonesData = () => {
    setLoader(true);
    const fileName = `${t('zone')}_${selectedPropertyName}_${selectedFieldItem.name}.zip`;
    let authHeader = getHeaders();
    let payload = {
      download: 'true',
      export: localStorage.getItem('session_id') ? 'true' : 'false'
    };
    let downloadUrl = localStorage.getItem('session_id')
      ? process.env.MZ_API_URL +
        `/to/shp?upload_to=${
          process.env.MZ_DATA_EXPORT_TO
        }&nidera_session_id=${localStorage.getItem('session_id')}`
      : process.env.MZ_API_URL + `/to/shp`;
    fetch(downloadUrl, {
      method: 'post',
      headers: {
        'Content-Type': 'application/json',
        Authorization: authHeader.common.Authorization
      },
      body: JSON.stringify(payload)
    })
      .then((response) => {
        if (response.status !== 200) {
          setErrorMessage(response.message || 'Something went wrong');
          setLoader(false);
          return;
        }
        return response.blob();
      })
      .then((blob) => {
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display:none';
          let url = window.URL.createObjectURL(blob);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        let success_message = `${t('The file')} ${fileName} ${t('was downloaded successfully.')}`;
        setSuccessMessage(success_message);
        setLoader(false);
      })
      .catch((error) => {
        setLoader(false);
        if (error?.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('Something went wrong');
        }
      });
  };

  const downloadRegenerateZonesData = (value) => {
    setLoader(true);
    const fileName = `aut_${t('zone')}_${selectedPropertyName}_${selectedFieldItem.name}.zip`;
    let authHeader = getHeaders();
    let payload = {
      field_id: selectedFieldItem.id,
      history: imageSelected.history
    };
    request
      .post('/productivity_zones', payload, {
        responseType: 'blob',
        baseURL: process.env.MZ_PROD_ZONE_API_URL,
        headers: {
          'Content-Type': 'application/json',
          Authorization: authHeader.common.Authorization
        },
        params: {
          n_zones: value.zone,
          min_area: value.removeOutlier || 100,
          output_format: value.outputFormat || shapefile
        }
      })
      .then((response) => {
        if (response.status !== 200) {
          setErrorMessage(response.message || 'Something went wrong 200');
          setLoader(false);
          return;
        }
        return response;
      })
      .then((blob) => {
        if (navigator.msSaveOrOpenBlob) {
          navigator.msSaveOrOpenBlob(blob, fileName);
        } else {
          let a = document.createElement('a');
          document.body.appendChild(a);
          a.style = 'display:none';
          let url = window.URL.createObjectURL(blob.data);
          a.href = url;
          a.download = fileName;
          a.click();
          window.URL.revokeObjectURL(url);
          a.remove();
        }
        return blob.data;
      })
      .then((val) => {
        if (localStorage.getItem('session_id')) {
          exportShapeFiletoNidera(val, fileName);
        } else {
          let success_message = `${t('The file')} ${fileName} ${t('was downloaded successfully.')}`;
          setSuccessMessage(success_message);
          setLoader(false);
        }
      })
      .catch((error) => {
        setLoader(false);
        if (error?.response?.data?.message) {
          setErrorMessage(error.response.data.message);
        } else {
          setErrorMessage('Something went wrong');
        }
      });
  };

  const exportShapeFiletoNidera = (blob, fileName) => {
    const ShapefileName = `Shapefile_${localStorage.getItem('session_id')}_${moment.utc()}.zip`;
    const reader = new FileReader();
    reader.onload = () => {
      const arrayBuffer = reader.result;
      const zipFile = new Blob([arrayBuffer], { type: 'application/zip' });
      const formdata = new FormData();
      formdata.append('File', zipFile, ShapefileName);
      const url = process.env.MZ_DATA_EXPORT_TO;

      request
        .post(url, formdata, {})
        .then((response) => {
          if (response.status !== 200) {
            setErrorMessage(response.message || 'Something went wrong 200');
            setLoader(false);
            return;
          }
          let success_message = `${t('The file')} ${fileName} ${t('was downloaded successfully.')}`;
          setSuccessMessage(success_message);
          setLoader(false);
          return response;
        })
        .catch((error) => {
          if (error?.response?.data?.message) {
            setErrorMessage(error.response.data.message);
          } else {
            setErrorMessage('Something went wrong');
          }
        });
    };
    reader.readAsArrayBuffer(blob);
  };

  const closeSideBar = () => {
    setSelectedImages([]);
    setSelectedZoneRes({});
    setSelectedRegenerateZoneAPIResponse({});
    setViewSelectedImgRes(false);
    setViewSidebar(false);
    setIsNewSidebarVisible(false);
    setFilterPopupData({
      selectedSource: ['sentinel'],
      selectedDate: '',
      selectedYear: '2017',
      cloudCoverValue: 1,
      isImgListToggleOn: false,
      isElevation: false
    });
    setSelectedNdviRgbVariabilitImages(null);
    setClearMap(!clearMap);
    setElevationData([]);
  };

  const onNDVIRGBVariabilityBtnsClick = (selectedImage) => {
    AmplitudeInstance.onNDVIandRGBImageSelect(selectedNdviRgbVariabilitImages.image_id);
    setActiveImage(selectedImage);
  };
  const [selectedShapeFileGeoJson, setSelectedShapeFileGeoJson] = React.useState(null);
  const getSelectedShapeFileGeoJson = (data, isShapeFileSelect = false) => {
    if (!data) {
      setSelectedShapeFileGeoJson(null);
    } else {
      if (isShapeFileSelect) {
        const newInstanceData = { ...data };
        setSelectedShapeFileGeoJson(newInstanceData);
        return;
      }
      const shapeFileImageData = {
        ...data,
        image: {
          imagePreview: ShapefileIcon
        }
      };
      let clonedSelectedImages = [...selectedImages];
      let index = clonedSelectedImages.findIndex((image) => {
        if (image?.shapeFileName) {
          return image.shapeFileName === shapeFileImageData.shapeFileName;
        }
      });
      if (index !== -1) {
        clonedSelectedImages.splice(index, 1);
      }
      clonedSelectedImages.push(shapeFileImageData);
      setSelectedImages(clonedSelectedImages);
      setSelectedNdviRgbVariabilitImages(null);
      setSelectedShapeFileGeoJson(data);
      setViewSelectedImgRes(true);
    }
  };

  const carouselRef = React.useRef();
  const [activeIndex, setActiveIndex] = React.useState(0);
  const getActiveIndex = (index) => {
    setActiveIndex(index);
  };
  const handleNext = () => carouselRef.current.next();
  const handlePrev = () => carouselRef.current.prev();
  let carouselResponsiveSettings = {
    responsive: [
      {
        breakpoint: 2561,
        settings: {
          rows: 1,
          slidesPerRow: 10
        }
      },
      {
        breakpoint: 1441,
        settings: {
          rows: 1,
          slidesPerRow: 6
        }
      },
      {
        breakpoint: 1024,
        settings: {
          rows: 1,
          slidesPerRow: 4
        }
      },
      {
        breakpoint: 768,
        settings: {
          rows: 1,
          slidesPerRow: 2
        }
      }
    ]
  };
  const onEditClick = (fieldId) => {
    const orgId = props.match.params.id;
    const farmId = props.match.params.farmId;
    history.push(URL_CONSTANTS.EDIT_FIELD_DRAW({ orgId, farmId, fieldId }), {
      editFieldFrom: 'MapsContainer'
    });
  };

  const getSelectedImages = (images) => {
    setSelectedImages(images);
  };

  const onImageDelete = (image, isDeleteFrmSidebar = false) => {
    if (image?.shapeFileName) {
      let clonedSelectedImages = [...selectedImages];
      let index = clonedSelectedImages.findIndex((imageData) => {
        if (imageData?.shapeFileName) {
          return imageData.shapeFileName === image.shapeFileName;
        }
      });
      if (index !== -1) {
        clonedSelectedImages.splice(index, 1);
        setSelectedImages(clonedSelectedImages);
        if (isDeleteFrmSidebar) {
          return;
        }
      }
      sideBarFilterModelRef.current.getDeletedShapeFileData(image);
    } else {
      sideBarFilterModelRef.current.getDeletedImage(image);
    }
  };
  return (
    <div data-testid='map-container-div'>
      {iff(isPropertyLoading, <Loader />)}
      {iff(isDataLoading, <Loader />)}
      {iff(loader, <Loader />)}
      {iff(
        errorMessage.length > 0,
        <ErrorModal
          data-testid='error-msg'
          visible={!!errorMessage}
          message={t(errorMessage)}
          okText={t('Ok')}
          onOk={() => {
            setErrorMessage('');
          }}
        />
      )}
      {iff(
        showTimeLimitDisclaimer,
        <SuccessModal
          data-testid='showTimeLimitDisclaimer-message'
          title={''}
          visible={showTimeLimitDisclaimer}
          message={t(
            'We are downloading the images of your lot for the automatic setting option.Please return within 2 hours'
          )}
          okText={t('Ok')}
          onOk={() => {
            setShowTimeLimitDisclaimer(null);
          }}
        />
      )}

      {iff(
        successMessage.length > 0,
        <SuccessModal
          data-testid='success-message-modal'
          visible={!!successMessage}
          message={t(successMessage)}
          cancelText={t('Ok')}
          onCancel={() => {
            setSuccessMessage('');
          }}
          okText={t('Continue with SMV')}
          onOk={() => {
            setSuccessMessage('');
            window.open(
              `${process.env.MZ_DATA_EXPORT_TO_REDIRECTION_URL}?session_id=${localStorage.getItem(
                'session_id'
              )}`,
              '_blank'
            );
          }}
        />
      )}

      {PageLayout({
        setSelectedImages: setSelectedImages,
        content: (
          <>
            {iff(
              selectedNdviRgbVariabilitImages !== null,
              <div className='ndvi_rgb_section'>
                <StyledNDVIRGBButtons className='NDVI_btn'>
                  <StyledButton
                    disabled={activeImage === 'NDVI'}
                    onClick={() => onNDVIRGBVariabilityBtnsClick('NDVI')}>
                    {t('NDVI')}
                  </StyledButton>
                </StyledNDVIRGBButtons>
                <StyledNDVIRGBButtons className='RGB_btn'>
                  <StyledButton
                    disabled={activeImage === 'RGB'}
                    onClick={() => onNDVIRGBVariabilityBtnsClick('RGB')}>
                    {t('RGB')}
                  </StyledButton>
                </StyledNDVIRGBButtons>
                <StyledVariabilityButtons className='variability_btn'>
                  <VariabilityButton
                    disabled={activeImage === 'Variability View'}
                    onClick={() => onNDVIRGBVariabilityBtnsClick('Variability View')}>
                    {t('Variability View')}
                  </VariabilityButton>
                </StyledVariabilityButtons>
              </div>
            )}
            {iff(
              !viewSelectedImgRes,
              <StyledAddNewFieldSection>
                <StyledButton
                  onClick={handleShowAddNewFieldPopup}
                  data-testid='add-new-field-btn-on-map'>
                  + {t('Add New Field')}
                </StyledButton>
              </StyledAddNewFieldSection>
            )}
            {iff(
              addNewFieldPopup,
              <CreateBoundaryModal
                onCancel={handleCancelAddNewFieldPopup}
                {...props}
                isAddNewClicked={true}
                addNewFieldFrom={'MapsContainer'}
              />
            )}
            {iff(
              !viewSelectedImgRes,
              <StyledManageMentZoneFieldSection>
                {process.env.SHOW_MANUAL_PRODUCTIVITY_ZONES_BUTTON === 'true' && (
                  <StyledButton
                    disabled={Object.keys(selectedFieldItem).length <= 0}
                    onClick={showFilterPopup}
                    style={{ marginRight: '15px' }}
                    data-testid='create-manul-zone-btn'>
                    1.{t('Manual')}
                  </StyledButton>
                )}
                {Object.keys(selectedFieldItem).length > 0 ? (
                  <StyledInfo>
                    {t('Click on the button to filter images and generate zones for the field')}
                  </StyledInfo>
                ) : (
                  <StyledInfo>{t('Please select a field')}</StyledInfo>
                )}

                {process.env.SHOW_NEW_PRODUCTIVITY_ZONES_BUTTON === 'true' && (
                  <StyledButton
                    disabled={disableAutomaticButton}
                    onClick={handleReGenerateZones}
                    data-testid='create-auto-zone-btn'>
                    2.{t('Automatic')}
                  </StyledButton>
                )}
                {Object.keys(selectedFieldItem).length > 0 ? (
                  <StyledInfo>
                    {t('Click on the button to filter images and generate zones for the field')}
                  </StyledInfo>
                ) : (
                  <StyledInfo>{t('Please select a field')}</StyledInfo>
                )}
              </StyledManageMentZoneFieldSection>
            )}
            {iff(
              filterPopup,
              <FilterPopup
                onCancel={cancelFilterPopup}
                {...props}
                onApplyFilter={onApplyFilter}
                filterPopupData={filterPopupData}
              />
            )}
            {iff(
              boxPlot,
              <BoxPlot
                onCancel={cancelShowBoxPlot}
                {...props}
                productivityZonesAPIResponse={productivityZonesAPIResponse}
                colorMultiplier={colorMultiplier}
              />
            )}
            <Maps
              geoJsons={allFields}
              focusLat={+lat}
              focusLong={+long}
              reload={true}
              handleFieldClick={handleFieldClick}
              selectedFieldId={selectedFieldItem.id}
              selectedFieldItem={selectedFieldItem}
              clusterRes={selectedZoneRes}
              productivityZonesAPIResponse={selectedRegenerateZoneAPIResponse}
              clearMap={clearMap}
              colorMultiplier={colorMultiplier}
              selectedNdviRgbVariabilitImages={selectedNdviRgbVariabilitImages}
              activeImage={activeImage}
              selectedShapeFileGeoJson={selectedShapeFileGeoJson}
            />
            {iff(
              allFields.length > 0 && !viewSelectedImgRes,
              <FieldListPopup
                fields={allFields}
                selectedFieldId={selectedFieldItem.id}
                handleFieldClick={handleFieldClick}
                onEditClick={onEditClick}
              />
            )}
            {viewSidebar && (
              <SideBarFilterModel
                data-testid='sideBar-Test-Model'
                closeSideBar={closeSideBar}
                viewSidebar={viewSidebar}
                showFilterPopup={showFilterPopup}
                filterResponseData={filterResponseData}
                filterPopupData={filterPopupData}
                selectedFieldItem={selectedFieldItem}
                selectedFieldItemName={selectedFieldItem.name}
                showSelectedNdviRgbVariabilitImages={showSelectedNdviRgbVariabilitImages}
                createZones={handleCreateZones}
                downloadZonesData={downloadZonesData}
                viewSelectedImgRes={viewSelectedImgRes}
                getSelectedShapeFileGeoJson={getSelectedShapeFileGeoJson}
                getSelectedImages={getSelectedImages}
                selectedImages={selectedImages}
                onImageDelete={onImageDelete}
                elevationData={elevationData}
                elevationErrorMessage={elevationErrorMessage}
                ref={sideBarFilterModelRef}
              />
            )}

            {isNewSidebarVisible && (
              <NewSideBarFilterModel
                data-testid='newSideBar-Test-Model'
                closeSideBar={closeSideBar}
                viewSidebar={viewSidebar}
                isNewSidebarVisible={isNewSidebarVisible}
                showBoxPlot={showBoxPlot}
                getproductivityZonesAPIResponse={getproductivityZonesAPIResponse}
                imageSelected={imageSelected}
                filterPopupData={filterPopupData}
                selectedFieldItem={selectedFieldItem}
                selectedFieldItemName={selectedFieldItem.name}
                downloadZonesData={downloadRegenerateZonesData}
              />
            )}

            {selectedImages && selectedImages.length > 0 && (
              <div className='bottom_carousel_section'>
                <Carousel
                  dots={false}
                  ref={carouselRef}
                  infinite={false}
                  {...carouselResponsiveSettings}
                  afterChange={getActiveIndex}>
                  {selectedImages?.map((data) => {
                    const isNotTiffFile = data.selectedAttribute !== 'tiff';
                    return (
                      <div
                        data-testid={`image-${data.image.acquisition_date}`}
                        className='carosel_image_section'
                        onClick={() =>
                          sideBarFilterModelRef.current.getActiveNdviRgbVariabilitImages(data)
                        }
                        key={data.image.image_id}
                        role='button'
                        tabIndex={0}>
                        <Tooltip placement='top' title={handleShapeFileName(data, isNotTiffFile)}>
                          <img
                            className='carosel_image'
                            src={data.image.imagePreview ? data.image.imagePreview : ''}
                            height='100%'
                            width='100%'
                            alt='carosel_image'
                          />
                        </Tooltip>
                        <CloseCircleOutlined
                          className='carosel_delete_image'
                          onClick={(eve) => {
                            eve.stopPropagation();
                            onImageDelete(data);
                          }}
                        />
                      </div>
                    );
                  })}
                </Carousel>
                {iff(
                  !props.isLoading,
                  <StyledButtonSection>
                    <StyledArrowButton disabled={activeIndex === 0} onClick={handlePrev}>
                      <LeftOutlined />
                    </StyledArrowButton>
                    <StyledArrowButton
                      disabled={activeIndex + 1 === Math.ceil(selectedImages.length / 6)}
                      onClick={handleNext}>
                      <RightOutlined />
                    </StyledArrowButton>
                  </StyledButtonSection>
                )}
              </div>
            )}
          </>
        )
      })}
    </div>
  );
};

MapsContainer.propTypes = {
  selectedProperty: PropTypes.object.isRequired,
  fetchOrgs: PropTypes.func,
  match: PropTypes.shape({
    params: PropTypes.shape({
      farmId: PropTypes.string,
      id: PropTypes.string
    })
  }),
  isLoading: PropTypes.bool
};

const mapStateToProps = (state) => ({
  selectedProperty: getSelectedProperty(state)
});

const mapDispatchToProps = (dispatch) => ({
  fetchOrgs: () => dispatch(fetchOrgs())
});

export const MapsContainerComponent = connect(mapStateToProps, mapDispatchToProps)(MapsContainer);
